<template>
    <div>
        <div class="__nav-point" v-bind:style="navPointStyle" />
        <div v-bind:style="canvasStyle">
            <canvas
                id="minos-game-canvas"
                class="__minos-game"
                v-bind:class="{ '--focused': focused }"
                v-bind:width="canvasWidth * canvasScaleMulitplier"
                v-bind:height="canvasHeight * canvasScaleMulitplier"
            />
        </div>
    </div>
</template>

<script>
import { AudioEffects } from '../classes/audioManager.js';
import InputManager from '../classes/inputManager.js';

import {
    FRAME_RATE,
    TILE_SIZE,
    Command,
    CommandTypes,
    Fx,
    FxTypes,
    Item,
    ItemTypes,
    GameModel,
    GameView,
    GameController,
    GamePhases,
    Pawn,
    TileTypes,
    TileDisplayTypes,
    Vector2,
    PawnStates,
} from '../../minos-shared/index.js';

const CLIENT_TIME_OFFSET = 5;
const CANVAS_SCALE_MULTIPLIER = 2;

export default {
    name: 'Game',
    data() {
        return {
            commandIndex: 0,
            lastUpdateTime: 0,
            lastUpdateDelta: 0,
            doRebuildGameState: false,
            commands: [],
            sentCommands: [],
            gameView: null,
            gameLoop: null,
            gameObjectsToDraw: [],
            inputManager: null,
            localGameModel: null,
            localGameController: null,
            fxGameObjects: [],
            fxGoIndex: 0,
            localRevealedMapIslands: [],
            localCurrentIslandIndex: -1,
            localItems: [],
            localTiles: [],
            allMapIslands: [],
            viewportWidth: 12,
            viewportHeight: 8,
            userPlayerIndex: -1,
            showNavPoint: false,
            navPointsOffset: new Vector2(0, 0),
            navPointHPin: 'none',
            navPointVPin: 'none',
            navPointExitOnscreen: false,
            canvasOrigin: new Vector2(0, 0),
        };
    },
    props: {
        focused: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String,
            default: '',
        },
        gameData: {
            type: Object,
            default: () => {
                return {};
            },
        },
        ping: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        clientTimeOffset() {
            // For every 10ms, add 1 frame to client advance of game clock...
            return Math.ceil(this.ping / 10);
        },
        lastProcessedCommand() {
            if (!this.gameData) {
                return -1;
            }
            return this.gameData.lastProcessedCommand;
        },
        canvasWidth() {
            return TILE_SIZE * this.viewportWidth;
        },
        canvasHeight() {
            return TILE_SIZE * this.viewportHeight;
        },
        boardWidth() {
            if (
                this.localGameModel &&
                this.localGameModel.tiles &&
                this.localGameModel.tiles.length > 0 &&
                this.localGameModel.tiles[0].length > 0
            ) {
                return this.localGameModel.tiles[0].length * TILE_SIZE;
            }
            return 0;
        },
        boardHeight() {
            if (this.localGameModel && this.localGameModel.tiles && this.localGameModel.tiles.length > 0) {
                return this.localGameModel.tiles.length * TILE_SIZE;
            }
            return 0;
        },
        navPointStyle() {
            if (!this.showNavPoint) {
                return {
                    visibility: 'hidden',
                };
            }
            // Calculate anchor point position...
            let left = 'auto';
            let right = 'auto';
            let rotation = 0;
            let scale = 1;
            let visibility = 'visible';
            if (this.navPointHPin === 'left') {
                left = 0;
                rotation = -90;
                if (this.navPointVPin === 'top') {
                    rotation = -45;
                } else if (this.navPointVPin === 'bottom') {
                    rotation = -135;
                }
            } else if (this.navPointHPin === 'right') {
                right = 0;
                rotation = 90;
                if (this.navPointVPin === 'top') {
                    rotation = 45;
                } else if (this.navPointVPin === 'bottom') {
                    rotation = 135;
                }
            } else {
                left = this.navPointsOffset.x + 'px';
            }
            let top = 'auto';
            let bottom = 'auto';
            if (this.navPointVPin === 'top') {
                top = 0;
            } else if (this.navPointVPin === 'bottom') {
                bottom = 0;
                if (this.navPointHPin === 'none') {
                    scale = -1;
                }
            } else {
                top = this.navPointsOffset.y + 'px';
            }
            // Display an 'unpinned' state...
            let bgImage = 'nav-point';
            if (this.navPointHPin === 'none' && this.navPointVPin === 'none') {
                bgImage = 'nav-point-unpinned';
                if (this.navPointExitOnscreen) {
                    visibility = 'hidden';
                }
            }
            return {
                top: top,
                right: right,
                bottom: bottom,
                left: left,
                visibility: visibility,
                backgroundImage: 'url(' + require('../assets/images/' + bgImage + '.svg') + ')',
                backgroundSize: 'cover',
                transform: 'rotate(' + rotation + 'deg) scaleY(' + scale + ')',
            };
        },
        canvasStyle() {
            return {
                width: this.canvasWidth + 'px',
                height: this.canvasHeight + 'px',
                transformOrigin: 'top left',
                transform: 'scale(' + 1 / CANVAS_SCALE_MULTIPLIER + ')',
            };
        },
        canvasScaleMulitplier() {
            return CANVAS_SCALE_MULTIPLIER;
        },
    },
    methods: {
        update() {
            const frameStartTime = Date.now();
            if (this.doRebuildGameState) {
                // Game complete flag...
                if (this.gameData.model.phase === GamePhases.OUTRO) {
                    this.clearGameLoop();
                    this.playAudio(AudioEffects.EXIT, -1, 1);
                    this.$emit('game-complete');
                } else {
                    // Store time values..
                    this.lastUpdateDelta = this.localGameModel.time - this.lastUpdateTime;
                    this.lastUpdateTime = this.localGameModel.time;
                    // Check for changes to pawn states and play associated FX...
                    this.checkForFxCues();
                    // Updating game state...
                    this.localGameController.resetLocalState(this.gameData.model, this.lastUpdateDelta, [this.userId]);
                    this.tryFastForward();
                    this.localGameModel.time = this.gameData.model.time + this.clientTimeOffset;
                    // Cull local sent commands...
                    this.sentCommands = this.sentCommands.filter((command) => {
                        return command.id > this.lastProcessedCommand;
                    });
                }
                this.doRebuildGameState = false;
            } else {
                this.checkInput();
                this.localGameController.update(0, this.commands, false);
                if (this.commands.length > 0) {
                    // Send commands to server...
                    this.$emit('send-commands', this.commands);
                    // Add to sentCommands, clear command queue...
                    this.sentCommands = this.sentCommands.concat(this.commands);
                    this.commands = [];
                }
            }

            // Logic for finding the camera focal point and board redrawing...
            const userPawn = this.localGameModel.gameObjects.filter((go) => {
                return go.id === this.userId;
            })[0];
            const focalPoint = new Vector2(0, 0);

            this.gameObjectsToDraw = [];

            if (userPawn) {
                focalPoint.x = userPawn.position.x + userPawn.size.x / 2;
                focalPoint.y = userPawn.position.y + userPawn.size.y / 2;

                // Compass/nav point drawing...

                if (userPawn.items.indexOf(ItemTypes.COMPASS) > -1) {
                    this.canvasOrigin = new Vector2(focalPoint.x - this.canvasWidth / 2, focalPoint.y - this.canvasHeight / 2);

                    this.navPointHPin = 'none';
                    if (this.localGameModel.exitPosition.x < this.canvasOrigin.x) {
                        this.navPointHPin = 'left';
                    }
                    if (this.localGameModel.exitPosition.x > this.canvasOrigin.x + this.canvasWidth) {
                        this.navPointHPin = 'right';
                    }

                    this.navPointVPin = 'none';
                    if (this.localGameModel.exitPosition.y < this.canvasOrigin.y) {
                        this.navPointVPin = 'top';
                    }
                    if (this.localGameModel.exitPosition.y > this.canvasOrigin.y + this.canvasHeight) {
                        this.navPointVPin = 'bottom';
                    }

                    const INDICATOR_SIZE = 20 / 2;

                    const cappedFocalPoint = new Vector2(
                        this.clamp(focalPoint.x, this.canvasWidth / 2, this.boardWidth - this.canvasWidth / 2 - TILE_SIZE),
                        this.clamp(focalPoint.y, this.canvasHeight / 2, this.boardHeight - this.canvasHeight / 2 - TILE_SIZE)
                    );

                    this.navPointsOffset = new Vector2(
                        this.clamp(
                            this.localGameModel.exitPosition.x - cappedFocalPoint.x,
                            -this.canvasWidth / 2 + INDICATOR_SIZE,
                            this.canvasWidth / 2 - INDICATOR_SIZE
                        ),
                        this.clamp(
                            this.localGameModel.exitPosition.y - cappedFocalPoint.y,
                            -this.canvasHeight / 2 + INDICATOR_SIZE,
                            this.canvasHeight / 2 - INDICATOR_SIZE
                        )
                    );

                    this.navPointsOffset.x = this.navPointsOffset.x + this.canvasWidth / 2 - INDICATOR_SIZE;
                    this.navPointsOffset.y = this.navPointsOffset.y + this.canvasHeight / 2 - INDICATOR_SIZE;

                    this.showNavPoint = true;
                } else {
                    this.showNavPoint = false;
                }

                // Find GOs to draw...
                for (let i = 0; i < this.localGameModel.gameObjects.length; i++) {
                    if (
                        this.localGameModel.gameObjects[i].currentIslandIndex === userPawn.currentIslandIndex ||
                        userPawn.items.indexOf(ItemTypes.GOGGLES) > -1
                    ) {
                        this.gameObjectsToDraw.push(this.localGameModel.gameObjects[i].id);
                    }
                }

                // If the pawn island changed or the pawn inventory changed, recalculate the tiles to draw and hide...
                if (userPawn.currentIslandIndex !== this.localCurrentIslandIndex || userPawn.items.length !== this.localItems.length) {
                    // Cache off pawn inventory...
                    this.localItems = userPawn.items;

                    // Find out if the new island (or previous island) was a door and play a sound effect...
                    let prevIslandType = TileTypes.WALKABLE;
                    let currIslandType = TileTypes.WALKABLE;
                    for (let i = 0; i < this.gameData.model.islands.length; i++) {
                        if (this.gameData.model.islands[i].id === this.localCurrentIslandIndex) {
                            prevIslandType = this.gameData.model.islands[i].type;
                        }
                        if (this.gameData.model.islands[i].id === userPawn.currentIslandIndex) {
                            currIslandType = this.gameData.model.islands[i].type;
                        }
                    }
                    if (prevIslandType === TileTypes.DOOR || currIslandType === TileTypes.DOOR) {
                        this.playAudioCallback(AudioEffects.SWITCH);
                    }

                    this.localCurrentIslandIndex = userPawn.currentIslandIndex;

                    if (this.localRevealedMapIslands.indexOf(userPawn.currentIslandIndex) < 0) {
                        this.localRevealedMapIslands.push(userPawn.currentIslandIndex);
                    }

                    let islandsToShow = this.localRevealedMapIslands;
                    if (this.localItems.indexOf(ItemTypes.GOGGLES) > -1) {
                        islandsToShow = this.allMapIslands;
                    }

                    let displayTiles = [];
                    for (let y = 0; y < this.localGameModel.tiles.length; y++) {
                        let row = [];
                        for (let x = 0; x < this.localGameModel.tiles[y].length; x++) {
                            row.push(TileDisplayTypes.HIDDEN);
                        }
                        displayTiles.push(row);
                    }
                    for (let i = 0; i < this.gameData.model.islands.length; i++) {
                        if (islandsToShow.indexOf(this.gameData.model.islands[i].id) > -1) {
                            for (let j = 0; j < this.gameData.model.islands[i].tiles.length; j++) {
                                const tileV2 = this.gameData.model.islands[i].tiles[j];
                                displayTiles[tileV2.y][tileV2.x] = TileDisplayTypes.REVEALED_INACTIVE;
                            }
                        }
                    }
                    this.navPointExitOnscreen = false;
                    for (let i = 0; i < this.gameData.model.islands.length; i++) {
                        if (this.gameData.model.islands[i].id === userPawn.currentIslandIndex) {
                            for (let j = 0; j < this.gameData.model.islands[i].tiles.length; j++) {
                                const tileV2 = this.gameData.model.islands[i].tiles[j];
                                const logicalTileType = this.localGameModel.tiles[tileV2.y][tileV2.x];
                                let displayTileType = TileDisplayTypes.REVEALED;
                                if (logicalTileType === TileTypes.EXIT) {
                                    displayTileType = TileDisplayTypes.EXIT;
                                    this.navPointExitOnscreen = true;
                                }
                                if (logicalTileType === TileTypes.DOOR) {
                                    displayTileType = TileDisplayTypes.DOOR;
                                }
                                displayTiles[tileV2.y][tileV2.x] = displayTileType;
                            }
                            for (let j = 0; j < this.gameData.model.islands[i].doors.length; j++) {
                                const doorV2 = this.gameData.model.islands[i].doors[j];
                                displayTiles[doorV2.y][doorV2.x] = TileDisplayTypes.DOOR_INACTIVE;
                            }
                        }
                    }
                    this.localTiles = displayTiles;
                }
            }

            // Send a combined list of server-tracked GOs and local (fx) GOs to the game view...
            this.gameObjectsToDraw = this.gameObjectsToDraw.concat(
                this.fxGameObjects.map((go) => {
                    return go.id;
                })
            );

            this.gameView.update(
                this.localTiles,
                this.localGameModel.gameObjects.concat(this.fxGameObjects),
                this.gameObjectsToDraw,
                focalPoint
            );

            // Check to see if any fx are flagged complete and remove...
            let activeFxGameObjects = [];
            for (let i = 0; i < this.fxGameObjects.length; i++) {
                this.fxGameObjects[i].update();
                if (!this.fxGameObjects[i].complete) {
                    activeFxGameObjects.push(this.fxGameObjects[i]);
                }
            }
            this.fxGameObjects = activeFxGameObjects;

            this.$emit('frame-render-time', Date.now() - frameStartTime);
        },
        checkInput() {
            if (!this.focused) {
                return;
            }
            if (this.inputManager.spacePressed) {
                this.commands.push(new Command(this.commandIndex, this.userId, CommandTypes.JUMP, this.localGameModel.time));
                this.commandIndex++;
            }
            if (this.inputManager.downPressed) {
                this.commands.push(new Command(this.commandIndex, this.userId, CommandTypes.DOWN, this.localGameModel.time));
                this.commandIndex++;
            }
            if (this.inputManager.leftPressed) {
                this.commands.push(new Command(this.commandIndex, this.userId, CommandTypes.LEFT, this.localGameModel.time));
                this.commandIndex++;
            }
            if (this.inputManager.rightPressed) {
                this.commands.push(new Command(this.commandIndex, this.userId, CommandTypes.RIGHT, this.localGameModel.time));
                this.commandIndex++;
            }
            if (this.inputManager.shiftLeftPressed || this.inputManager.shiftRightPressed) {
                this.commands.push(new Command(this.commandIndex, this.userId, CommandTypes.ATTACK, this.localGameModel.time));
                this.commandIndex++;
            }
            if (this.inputManager.debugPressed) {
                this.commands.push(new Command(this.commandIndex, this.userId, CommandTypes.DEBUG, this.localGameModel.time));
                this.commandIndex++;
            }
        },
        setupGame() {
            // Local game controller setup...
            this.localGameController = new GameController(this.localGameModel, [this.userId], false, null);
            this.localGameController.onPlayAudio = this.playAudioCallback;
            this.localGameController.onItemPickedUp = this.onItemPickedUp;

            this.localGameModel.time = this.localGameModel.time + this.clientTimeOffset;

            // Game view setup...
            const spritesheet = new Image();
            spritesheet.src = require('../../src/assets/images/tiles.png');
            const canvas = document.getElementById('minos-game-canvas');
            const context = canvas.getContext('2d');
            this.gameView = new GameView(
                this.userId,
                canvas,
                context,
                spritesheet,
                this.viewportWidth,
                this.viewportHeight,
                CANVAS_SCALE_MULTIPLIER
            );

            // Game loop
            // TODO: Need a better game loop that we can run on the server and client...
            this.gameLoop = setInterval(this.update.bind(this), 1000 / FRAME_RATE);

            // Store off all islands to avoid computing att runtime...
            this.allMapIslands = this.localGameModel.islands.map((island) => {
                return island.id;
            });

            // Start checking input...
            this.inputManager.start();
        },
        tryFastForward() {
            // Reapply any commands not yet processed by the server...
            const delta = this.localGameModel.time - this.gameData.model.time;
            this.localGameModel.time = this.localGameModel.time - delta;
            for (let i = 0; i < delta; i++) {
                const frameTime = this.gameData.model.time + i + 1;
                const unprocessedCommandsAtFrame = this.sentCommands.filter((command) => {
                    return command.id > this.lastProcessedCommand && command.time === frameTime;
                });
                this.localGameController.update(0, unprocessedCommandsAtFrame, true);
            }
        },
        checkForFxCues() {
            // Check for deltas in non-player pawn states, use to play SFX...
            const thePawn = this.localGameModel.gameObjects.filter((go) => {
                return go.id === this.userId;
            })[0];
            if (!thePawn) {
                return;
            }
            const prevNonplayerPawns = this.localGameModel.gameObjects.filter((go) => {
                return go.id !== this.userId && go.type === 'pawn';
            });
            const currNonplayerPawns = this.gameData.model.gameObjects.filter((go) => {
                return go.id !== this.userId && go.type === 'pawn';
            });
            currNonplayerPawns.forEach((currPawn) => {
                const filteredPrevNonplayerPawns = prevNonplayerPawns.filter((prevPawn) => {
                    return currPawn.id === prevPawn.id;
                });
                let prevPawn = null;
                if (filteredPrevNonplayerPawns.length > 0) {
                    prevPawn = filteredPrevNonplayerPawns[0];
                }
                if (prevPawn) {
                    // TODO: Trying to fix a bug where walk audio doesn't turn off, remove this if it doesn't work...
                    if (currPawn.state !== PawnStates.WALK) {
                        // Stop walk loop...
                        this.stopAudioLoop(AudioEffects.WALK, currPawn.index, 1);
                    }
                    // Set volume based on proximity to the user pawn...
                    let volume = 0.2;
                    if (currPawn.currentIslandIndex === thePawn.currentIslandIndex) {
                        volume = 1;
                    }
                    if (currPawn.state !== PawnStates.IDLE && currPawn.state !== prevPawn.state) {
                        if (currPawn.state === PawnStates.WALK) {
                            // Start walk loop...
                            this.startAudioLoop(AudioEffects.WALK, currPawn.index, volume);
                        } else {
                            let clip = AudioEffects.WALK;
                            if (currPawn.state === PawnStates.JUMP || currPawn.state === PawnStates.DOUBLE_JUMP) {
                                clip = AudioEffects.JUMP;
                            }
                            if (currPawn.state === PawnStates.ATTACK) {
                                clip = AudioEffects.BUZZ;
                            }
                            this.playAudio(clip, currPawn.index, volume);
                        }
                    }
                    if (prevPawn.currentIslandIndex !== currPawn.currentIslandIndex) {
                        this.playAudio(AudioEffects.SWITCH, currPawn.index, volume);
                        // If one of the pawns has entered/exited the local pawn's island, display a visual effect...
                        if (
                            prevPawn.currentIslandIndex === this.localCurrentIslandIndex ||
                            currPawn.currentIslandIndex === this.localCurrentIslandIndex
                        ) {
                            const fxPos = Vector2.average(prevPawn.position, currPawn.position);
                            this.fxGameObjects.push(new Fx('fx-' + this.fxGoIndex, FxTypes.PLAYER_ENTER, fxPos.x, fxPos.y));
                            this.fxGoIndex++;
                        }
                    }
                    if (prevPawn.active !== currPawn.active) {
                        this.fxGameObjects.push(new Fx('fx-' + this.fxGoIndex, FxTypes.PLAYER_ENTER, currPawn.x, currPawn.y));
                        this.fxGoIndex++;
                    }
                }
            });
        },
        playAudioCallback(clip) {
            // Called from GameController...
            if (this.userPlayerIndex < 0) {
                for (let i = 0; i < this.localGameModel.gameObjects.length; i++) {
                    if (this.localGameModel.gameObjects[i].id === this.userId) {
                        this.userPlayerIndex = this.localGameModel.gameObjects[i].index;
                        break;
                    }
                }
            }
            this.playAudio(clip, this.userPlayerIndex, 1);
        },
        playAudio(clip, channel, volume) {
            this.$emit('play-audio', clip, channel, volume);
        },
        startAudioLoop(clip, channel, volume) {
            this.$emit('start-audio-loop', clip, channel, volume);
        },
        stopAudioLoop(clip, channel, volume) {
            this.$emit('stop-audio-loop', clip, channel, volume);
        },
        onItemPickedUp(itemGO, pawnGO) {
            if (pawnGO.id === this.userId) {
                this.$emit('picked-up-item', itemGO.itemType);
                this.fxGameObjects.push(new Fx('fx-' + this.fxGoIndex, FxTypes.ITEM_PICKUP, itemGO.x, itemGO.y));
                this.fxGoIndex++;
            }
        },
        clearGameLoop() {
            clearInterval(this.gameLoop);
            this.inputManager.stop();
            this.stopAudioLoop(AudioEffects.MUSIC, -1, 1);
        },
        // TODO: Move to a utils class...
        clamp(num, min, max) {
            return Math.min(Math.max(num, min), max);
        },
    },
    watch: {
        gameData: {
            deep: true,
            handler() {
                if (this.gameData) {
                    if (this.localGameModel === null) {
                        this.localGameModel = GameModel.copy(this.gameData.model);
                        this.setupGame();
                    } else {
                        this.doRebuildGameState = true;
                    }
                }
            },
        },
    },
    created() {
        this.inputManager = new InputManager();
        this.startAudioLoop(AudioEffects.MUSIC, -1, 1);
    },
    beforeUnmount() {
        this.clearGameLoop();
    },
};
</script>

<style lang="scss" scoped>
$nav-point-size: 20px;

.__minos-game {
    position: absolute;
    outline: solid 4px $color-lines;
    opacity: 0.75;
    z-index: 0;
}

.__nav-point {
    position: absolute;
    width: $nav-point-size;
    height: $nav-point-size;
    z-index: 10;
}

.--focused {
    outline: solid 4px white;
    opacity: 1;
}
</style>