<template>
    <div class="gameplay">
        <basic-dialog
            v-if="doShowGameComplete"
            v-bind:option-a-label="'Back to Lobby'"
            v-bind:option-b-label="nextBoardNameLabel"
            v-on:option-a-clicked="onEndGame"
            v-on:option-b-clicked="onNextLevel"
            v-bind:timeout="3"
        >
            <template v-slot:content>
                <div class="__game-complete-content">
                    <h3>
                        <span class="--highlight">{{ currentBoardName }}</span> complete.
                    </h3>
                    <h3>
                        <b
                            v-bind:class="{
                                '--p0': winner.index === -1,
                                '--p1': winner.index === 0,
                                '--p2': winner.index === 1,
                                '--p3': winner.index === 2,
                                '--p4': winner.index === 3,
                            }"
                            >{{ winnerName }}</b
                        >
                        found the space exit.
                    </h3>
                </div>
            </template>
        </basic-dialog>
        <top-bar
            v-bind:session-id="sessionId"
            v-bind:volume="volume"
            v-bind:exit-string="'End Game'"
            v-on:leave="onEndGame"
            v-on:show-story="$emit('show-story')"
            v-on:copy-id="playClickAudio"
            v-on:volume-changed="volumeChanged"
        />
        <div class="__content">
            <div class="__side-column">
                <div class="__column-inner">
                    <player-portrait
                        class="--no-border-left"
                        v-for="player in playerPortraits"
                        v-bind:key="player.id"
                        v-bind:display-name="player.name"
                        v-bind:index="player.index"
                        v-bind:items="player.items"
                        v-bind:meter-fill="player.cooldown"
                        v-bind:dazed="player.dazed"
                        v-bind:meter-max="attackCooldownTime"
                        v-on:play-audio="playAudio"
                    />
                </div>
            </div>
            <div class="__center-column">
                <div class="__board-name-container">
                    <h2 class="__board-name">{{ currentBoardName }}</h2>
                </div>
                <div class="__game-container">
                    <game
                        class="game"
                        v-bind:user-id="userId"
                        v-bind:game-data="gameData"
                        v-bind:focused="currentFocus === 'game'"
                        v-bind:ping="ping"
                        v-on:send-commands="sendCommands"
                        v-on:game-complete="onGameComplete"
                        v-on:click="setFocus('game')"
                        v-on:play-audio="playAudio"
                        v-on:start-audio-loop="startAudioLoop"
                        v-on:stop-audio-loop="stopAudioLoop"
                        v-on:frame-render-time="onFrameRendered"
                        v-on:picked-up-item="showItemPickupMessage"
                    />
                    <div class="__message-container">
                        <div v-if="doShowItemPickupMessage" class="__message">
                            <div class="__message-item">{{ lastCollectedItem }}</div>
                            ACQUIRED
                        </div>
                    </div>
                </div>
                <div class="__controls-container">
                    <div class="__control">
                        <div class="__control-key --square">&#8592;</div>
                        <div class="__control-key --square">&#8594;</div>
                        <div class="__control-label">Space Walk</div>
                    </div>
                    <div class="__control">
                        <div class="__control-key">Space</div>
                        <div class="__control-label">Space Jump</div>
                    </div>
                    <div class="__control">
                        <div class="__control-key">&#8593; Shift</div>
                        <div class="__control-label">Zap</div>
                    </div>
                </div>
            </div>
            <div class="__side-column --right">
                <div class="__column-inner">
                    <chat
                        v-bind:class="{
                            '--no-border-right': currentFocus !== 'chat',
                            '--no-border-right-focused': currentFocus === 'chat',
                        }"
                        v-bind:messages="messages"
                        v-bind:focused="currentFocus === 'chat'"
                        v-on:send-message="sendMessage"
                        v-on:click="setFocus('chat')"
                    />
                </div>
            </div>
        </div>
        <div class="__footer" />
    </div>
</template>

<script>
import { ATTACK_COOLDOWN_TIME, GamePhases, PawnStates } from '../../minos-shared/index.js';
import { AudioEffects } from '../classes/audioManager.js';

import BasicDialog from '../components/BasicDialog.vue';
import Chat from '../components/Chat.vue';
import Game from '../components/Game.vue';
import PlayerPortrait from '../components/PlayerPortrait.vue';
import TopBar from '../components/TopBar.vue';

export default {
    name: 'GamePlay',
    components: {
        basicDialog: BasicDialog,
        chat: Chat,
        game: Game,
        playerPortrait: PlayerPortrait,
        topBar: TopBar,
    },
    props: {
        userId: {
            type: String,
            default: '',
        },
        sessionId: {
            type: String,
            default: '',
        },
        users: {
            type: Array,
            default: () => {
                return [];
            },
        },
        messages: {
            type: Array,
            default: () => {
                return [];
            },
        },
        gameData: {
            type: Object,
            default: () => {
                return {};
            },
        },
        volume: {
            type: Number,
            default: 0,
        },
        ping: {
            type: Number,
            default: 0,
        },
        selectedBoardIndex: {
            type: Number,
            default: 0,
        },
        boardNames: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            currentMessage: '',
            currentFocus: 'game',
            itemPickupTimer: null,
            doShowItemPickupMessage: false,
            doShowGameComplete: false,
            lobbyButtonDisabled: false,
            lastCollectedItemType: -1,
        };
    },
    computed: {
        playerPortraits() {
            if (
                this.users.length === 0 ||
                !this.gameData ||
                !this.gameData.model ||
                !this.gameData.model.gameObjects ||
                !this.gameData.model.gameObjects.length === 0
            ) {
                return [];
            }
            return this.users.map((user) => {
                let index = 0;
                let items = [];
                let cooldown = 0;
                let dazed = false;
                for (let i = 0; i < this.gameData.model.gameObjects.length; i++) {
                    if (this.gameData.model.gameObjects[i].id === user.id) {
                        index = this.gameData.model.gameObjects[i].index;
                        items = this.gameData.model.gameObjects[i].items;
                        cooldown = this.gameData.model.gameObjects[i].attackCooldown;
                        dazed = this.gameData.model.gameObjects[i].state === PawnStates.DAZED;
                    }
                }
                return {
                    id: user.id,
                    name: user.name,
                    index: index,
                    items: items,
                    cooldown: cooldown,
                    dazed: dazed,
                };
            });
        },
        winner() {
            const defaultWinner = {
                id: '',
                index: 0,
            };
            if (!this.gameData) {
                return defaultWinner;
            }
            if (this.users && this.users.length > 0) {
                const users = this.users.filter((user) => {
                    return user.id === this.gameData.model.winner;
                });
                if (users.length > 0) {
                    return users[0];
                } else {
                    return defaultWinner;
                }
            } else {
                return defaultWinner;
            }
        },
        winnerName() {
            if (!this.gameData) {
                return '';
            }
            if (this.winner.id === this.userId) {
                return 'you';
            }
            return this.winner.name;
        },
        lastCollectedItem() {
            if (this.lastCollectedItemType === 0) {
                return 'SPACE BINOCULARS';
            }
            if (this.lastCollectedItemType === 1) {
                return 'SPACE BOOTS';
            }
            if (this.lastCollectedItemType === 2) {
                return 'SPACE COMPASS';
            }
            return '';
        },
        attackCooldownTime() {
            return ATTACK_COOLDOWN_TIME;
        },
        currentBoardName() {
            if (this.boardNames.length === 0 || this.selectedBoardIndex > this.boardNames.length - 1) {
                return '';
            }
            return 'Sector ' + this.boardNames[this.selectedBoardIndex];
        },
        nextBoardName() {
            if (this.boardNames.length === 0) {
                return '';
            }
            if (this.selectedBoardIndex + 1 < this.boardNames.length) {
                return this.boardNames[this.selectedBoardIndex + 1];
            }
            return this.boardNames[0];
        },
        nextBoardNameLabel() {
            return 'Enter Sector ' + this.nextBoardName + ' →';
        },
        gamePhase() {
            if (this.gameData && this.gameData.model) {
                return this.gameData.model.phase;
            }
            return '';
        },
    },
    methods: {
        onEndGame() {
            this.$emit('end-game');
            this.playClickAudio();
        },
        onNextLevel() {
            this.$emit('next-level');
            this.playClickAudio();
        },
        onGameComplete() {
            this.doShowGameComplete = true;
        },
        sendCommands(commands) {
            this.$emit('send-commands', commands);
        },
        sendMessage(msg) {
            this.$emit('send-message', msg);
            this.playClickAudio();
        },
        setFocus(element) {
            this.currentFocus = element;
        },
        showItemPickupMessage(itemType) {
            this.lastCollectedItemType = itemType;
            if (this.itemPickupTimer) {
                clearInterval(this.itemPickupTimer);
                this.itemPickupTimer = null;
            }
            this.doShowItemPickupMessage = true;
            this.itemPickupTimer = setTimeout(() => {
                this.doShowItemPickupMessage = false;
            }, 3000);
            this.playAudio(AudioEffects.POWERUP, -1, 1);
        },
        playAudio(clip, channel, volume) {
            this.$emit('play-audio', clip, channel, volume);
        },
        startAudioLoop(clip, channel, volume) {
            this.$emit('start-audio-loop', clip, channel, volume);
        },
        stopAudioLoop(clip, channel, volume) {
            this.$emit('stop-audio-loop', clip, channel, volume);
        },
        playClickAudio() {
            this.$emit('play-audio', AudioEffects.CLICK, -1, 1);
        },
        onFrameRendered(renderTime) {
            this.$emit('frame-render-time', renderTime);
        },
        volumeChanged(val) {
            this.setFocus('volume');
            this.$emit('volume-changed', val);
        },
    },
    watch: {
        doShowGameComplete: {
            handler() {
                if (this.doShowGameComplete) {
                    this.lobbyButtonDisabled = true;
                    setTimeout(() => {
                        this.lobbyButtonDisabled = false;
                    }, 3000);
                }
            },
        },
        gamePhase: {
            handler(nv, ov) {
                if (ov === GamePhases.OUTRO && nv !== GamePhases.OUTRO && this.doShowGameComplete) {
                    this.doShowGameComplete = false;
                }
            },
        },
    },
    mounted() {
        this.currentFocus = 'game';
    },
};
</script>

<style lang="scss" scoped>
$controls-height: 60px;

.gameplay {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.__header {
    height: 40px;
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 10px 20px;
    background-color: $color-ui-container;
}
.__footer {
    height: 60px;
    width: 100%;
}
.__content {
    width: 100%;
    display: flex;
    flex-grow: 1;
    gap: 20px;
}
.__side-column {
    position: relative;
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.--no-border-right-focused {
    border: solid 2px white !important;
    border-right: none !important;
}

@media screen and (min-width: 1360px) {
    .__side-column {
        padding: 0 10px;
    }
    .--no-border-left {
        border: solid 2px $color-lines !important;
    }
    .--no-border-right {
        border: solid 2px $color-lines !important;
    }
    .--no-border-right-focused {
        border: solid 2px white !important;
    }
}

.__column-inner {
    width: 225px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    height: 450px;
}
.--right {
    align-items: flex-end;
}
.__center-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.__board-name-container {
    height: $controls-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.__board-name {
    font-size: 20px;
}
.__game-container {
    position: relative;
}
.__dialog-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.__dialog {
    width: 350px;
    padding: 30px;
    background-color: black;
    border: solid 2px white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        margin: 0;
    }
    p {
        margin: 0;
    }
    button {
        margin: 0;
    }
}
.__dialog-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 30px;
}
.__message-container {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.__controls-container {
    height: $controls-height;
    display: flex;
    align-items: center;
}
.__control {
    margin-right: 30px;
    display: flex;
    align-items: center;
    color: $color-secondary-text;
    font-family: nitti, monospace;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
}
.__control-key {
    min-width: 50px;
    height: 20px;
    padding: 0 6px;
    margin-right: 3px;
    border: solid 2px $color-secondary-text;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.--square {
    padding: 0;
    min-width: 20px;
    font-size: 16px;
}
.__control-label {
    margin-left: 5px;
    color: $color-secondary-text;
}
.__message {
    display: flex;
    padding: 6px;
    font-family: nitti, monospace;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    background-color: $color-ui-container;
    .__message-item {
        color: $color-text-highlight;
        margin-right: 12px;
    }
}
.__game-complete-content {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding-top: 20px;
}
</style>