
export default class InputManager {

    constructor() {
        this._keys = {};
    }

    start() {
        window.addEventListener("keydown", this.onKeyDown.bind(this));
        window.addEventListener("keyup", this.onKeyUp.bind(this));
    }

    stop() {
        window.removeEventListener("keydown", this.onKeyDown.bind(this));
        window.removeEventListener("keyup", this.onKeyUp.bind(this));
    }

    onKeyDown(e) {
        this._keys[e.code] = true;
    }

    onKeyUp(e) {
        this._keys[e.code] = false;
    }

    get upPressed() {
        if (this._keys["ArrowUp"] === undefined) {
            return false;
        }
        return this._keys["ArrowUp"];
    }

    get downPressed() {
        if (this._keys["ArrowDown"] === undefined) {
            return false;
        }
        return this._keys["ArrowDown"];
    }

    get leftPressed() {
        if (this._keys["ArrowLeft"] === undefined) {
            return false;
        }
        return this._keys["ArrowLeft"];
    }

    get rightPressed() {
        if (this._keys["ArrowRight"] === undefined) {
            return false;
        }
        return this._keys["ArrowRight"];
    }

    get spacePressed() {
        if (this._keys["Space"] === undefined) {
            return false;
        }
        return this._keys["Space"];
    }

    get shiftLeftPressed() {
        if (this._keys["ShiftLeft"] === undefined) {
            return false;
        }
        return this._keys["ShiftLeft"];
    }

    get shiftRightPressed() {
        if (this._keys["ShiftRight"] === undefined) {
            return false;
        }
        return this._keys["ShiftRight"];
    }

    get debugPressed() {
        if (this._keys["KeyD"] === undefined) {
            return false;
        }
        return this._keys["KeyD"];
    }
}