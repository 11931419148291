<template>
    <div class="player-portrait">
        <div class="__column">
            <div class="__top">
                <div class="__left">
                    <div class="__portrait">
                        <img
                            v-bind:class="{
                                '--p1-tint': index === 0,
                                '--p2-tint': index === 1,
                                '--p3-tint': index === 2,
                                '--p4-tint': index === 3,
                            }"
                            v-bind:src="portraitImage"
                        />
                    </div>
                </div>
                <div class="__right">
                    <div class="__icons">
                        <div v-if="showGoggles" class="__powerup-icon">
                            <img src="../assets/images/icon-goggles.svg" />
                        </div>
                        <div v-if="showBoots" class="__powerup-icon">
                            <img src="../assets/images/icon-boots.svg" />
                        </div>
                        <div v-if="showCompass" class="__powerup-icon">
                            <img src="../assets/images/icon-compass.svg" />
                        </div>
                    </div>
                    <h3 class="__player-name --primary">{{ displayName }}</h3>
                </div>
            </div>
            <div class="__meter">
                <div
                    class="__fill"
                    v-bind:class="{ '--filling': meterFill < meterMax, '--full': meterFill === 0, '--blink': meterFilled }"
                    v-bind:style="meterWidth"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { AudioEffects } from '../classes/audioManager.js';
import { ItemTypes } from '../../minos-shared/index.js';

const DEFAULT_ANIM_FRAME_COUNT = 20;

export default {
    name: 'PlayerPortrait',
    props: {
        index: {
            type: Number,
            default: 0,
        },
        displayName: {
            type: String,
            default: 'player_name',
        },
        dazed: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },
        meterFill: {
            type: Number,
            default: 50,
        },
        meterMax: {
            type: Number,
            default: 100,
        },
    },
    data() {
        return {
            portraitAnimInterval: null,
            defaultAnimFrame: 0,
            dazedAnimFrame: 0,
            meterAnimTimeout: null,
            meterFilled: false,
        };
    },
    computed: {
        portraitImage() {
            if (this.dazed) {
                return require('../assets/images/portrait-dazed' + (this.dazedAnimFrame + 1) + '.svg');
            }
            if (this.defaultAnimFrame === 0) {
                return require('../assets/images/portrait-squint.svg');
            }
            return require('../assets/images/portrait.svg');
        },
        showGoggles() {
            return this.items.indexOf(ItemTypes.GOGGLES) > -1;
        },
        showBoots() {
            return this.items.indexOf(ItemTypes.BOOTS) > -1;
        },
        showCompass() {
            return this.items.indexOf(ItemTypes.COMPASS) > -1;
        },
        meterWidth() {
            return {
                width: (1 - this.meterFill / this.meterMax) * 100 + '%',
            };
        },
    },
    methods: {},
    watch: {
        meterFill: {
            immediate: false,
            handler() {
                if (this.meterFill === 0) {
                    this.meterFilled = true;
                    this.$emit('play-audio', AudioEffects.METER_FULL, -1, 1);
                    this.meterAnimTimeout = setTimeout(() => {
                        this.meterFilled = false;
                    }, 500);
                }
            },
        },
    },
    mounted() {
        this.portraitAnimInterval = setInterval(() => {
            if (this.dazed) {
                this.dazedAnimFrame++;
                if (this.dazedAnimFrame === 2) {
                    this.dazedAnimFrame = 0;
                }
            } else {
                this.defaultAnimFrame++;
                if (this.defaultAnimFrame === DEFAULT_ANIM_FRAME_COUNT) {
                    this.defaultAnimFrame = 0;
                }
            }
        }, 250);
    },
    beforeUnmount() {
        clearInterval(this.portraitAnimInterval);
    },
};
</script>

<style lang="scss" scoped>
.player-portrait {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: solid 2px $color-lines;
    .__column {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        gap: 10px;
    }
    .__top {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        .__left {
            .__portrait {
                width: 44px;
                height: 44px;
            }
        }
        .__right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .__player-name {
                line-height: 0.9;
            }
            .__icons {
                display: flex;
                .__powerup-icon {
                    height: 22px;
                    width: 22px;
                }
            }
        }
    }
    .__meter {
        position: relative;
        width: 100%;
        height: 10px;
        background-color: rgba(0, 0, 0, 0.25);
        .__fill {
            height: 100%;
            background-color: $color-text-highlight;
        }
        .--filling {
            background-color: $color-lines;
        }
        .--full {
            background-color: $color-text-highlight;
        }
        .--blink {
            background-color: white;
            animation: meter-blink 0.25s step-start infinite;
        }
        @keyframes meter-blink {
            0% {
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }
    background-color: $color-ui-container;
}
</style>