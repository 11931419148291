<template>
    <div class="player-list">
        <div class="__header">
            <h2>Rangers</h2>
        </div>
        <div class="__players">
            <div class="__player-name" v-for="player in players" v-bind:key="player.index">
                <h3
                    v-bind:class="{
                        '--p0': player.index === -1,
                        '--p1': player.index === 0,
                        '--p2': player.index === 1,
                        '--p3': player.index === 2,
                        '--p4': player.index === 3,
                    }"
                >
                    <b>{{ player.name }}</b>
                    <i v-if="player.id === playerId"> (You)</i>
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlayerList',
    props: {
        players: {
            type: Array,
            default: () => {
                return [];
            },
        },
        playerId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.player-list {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 5px;
    background-color: $color-ui-container;
    border: solid 2px $color-lines;
}

.__header {
    border-bottom: solid 2px $color-lines;
    padding-bottom: 10px;
}

.__players {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
</style>