<template>
    <li class="session-entry">
        <div class="__labels">
            <label class="--table-name-width">{{ sessionName }}</label>
            <label>{{ playerCountString }}</label>
        </div>
        <button class="__join-button --secondary-button" v-bind:disabled="joinDisabled" v-on:click="tryJoinSession">Join</button>
    </li>
</template>

<script>
export default {
    name: 'SessionEntry',
    props: {
        session: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {};
    },
    computed: {
        sessionName() {
            if (this.session && this.session.name) {
                return this.session.name;
            }
            return '';
        },
        playerCountString() {
            if (this.session && this.session.userCount) {
                return this.session.userCount + '/4';
            }
            return '';
        },
        joinDisabled() {
            if (this.session && this.session.userCount) {
                if (this.session.userCount < 4) {
                    return false;
                }
            }
            return true;
        },
    },
    methods: {
        tryJoinSession() {
            this.$emit('try-join-session', this.session.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.session-entry {
    border-bottom: solid 2px $color-lines;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .__labels {
        display: flex;
        width: 250px;
    }
    .__join-button {
        width: 70px;
    }
}

::marker {
    content: '';
}

li {
    margin: 0;
    padding: 0;
}
</style>