<template>
    <button class="icon-button" v-bind:class="{ '--large': large }">
        <slot name="icon"></slot>
    </button>
</template>

<script>
export default {
    name: 'IconButton',
    props: {
        large: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-button {
    width: auto;
    background-color: transparent;
    outline: none;
    margin: 0;
    padding: 0;
    opacity: 0.9;
    padding: 2px;
    width: 16px;
    height: 16px;
    border: solid 1px transparent;
    &:hover {
        opacity: 1;
    }
    &:focus {
        border: solid 1px $color-secondary-text;
    }
}
.--large {
    width: 40px;
    height: 22px;
    padding: 0;
    border: solid 2px transparent;
    &:focus {
        border: solid 2px white;
    }
}
</style>