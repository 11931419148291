<template>
    <div class="login">
        <basic-dialog
            v-if="doShowActiveSessionsDialog"
            v-bind:option-a-label="activeSessionsButtonLabel"
            v-on:option-a-clicked="hideActiveSessionsDialog"
        >
            <template v-slot:content>
                <div class="__dialog-content">
                    <h2>Active Games</h2>
                    <session-list v-bind:sessions="activeSessions" v-on:try-join-session="tryJoinSession" />
                </div>
            </template>
        </basic-dialog>
        <div class="__title-container">
            <img src="../assets/images/srg-logo.svg" alt="Space Rangers Go!" />
        </div>
        <div class="__form-container">
            <div v-if="!nameEntryComplete">
                <form class="__form-centered" v-on:submit.prevent="tryAssignName">
                    <h3>What's your name, ranger?</h3>
                    <div>
                        <input class="--form-width" type="text" name="localUserName" v-model="localUserName" maxlength="10" />
                    </div>
                    <button class="--lg --form-width" type="submit" v-bind:disabled="localUserName === ''">Next →</button>
                </form>
            </div>
            <div v-else class="__form-centered">
                <div class="__name-row">
                    <h2>Hi,</h2>
                    <div class="__name-read" v-show="!isEditingName">
                        <h2>{{ localUserName }}</h2>
                        <icon-button v-on:click="editName">
                            <template v-slot:icon>
                                <img src="../assets/images/icon-edit.svg" />
                            </template>
                        </icon-button>
                    </div>
                    <form class="__name-write" v-show="isEditingName" v-on:submit.prevent="tryAssignEditedName">
                        <input
                            class="__input --lg-input"
                            style="margin-top: -10px"
                            ref="nameEntry"
                            type="text"
                            maxlength="10"
                            v-model="editedUserName"
                        />
                        <icon-button>
                            <template v-slot:icon>
                                <img src="../assets/images/icon-complete.svg" />
                            </template>
                        </icon-button>
                    </form>
                </div>
                <div class="__form-row">
                    <form v-on:submit.prevent="tryCreateSession">
                        <button class="--x-lg --form-width" type="submit">Create Game</button>
                    </form>
                    <h3>OR</h3>
                    <form class="--stacked" v-on:submit.prevent="showActiveSessionsDialog">
                        <button class="--x-lg --form-width" type="submit">Join Game</button>
                    </form>
                </div>
                <div class="__error-row">
                    <p class="--error" v-if="errorString !== ''">{{ errorString }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AudioEffects } from '../classes/audioManager.js';

import BasicDialog from '../components/BasicDialog.vue';
import IconButton from '../components/IconButton.vue';
import SessionList from '../components/SessionList.vue';

export default {
    name: 'Login',
    components: {
        basicDialog: BasicDialog,
        iconButton: IconButton,
        sessionList: SessionList,
    },
    props: {
        activeSessions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        sessionError: {
            type: String,
            default: '',
        },
        userName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isEditingName: false,
            sessionId: '',
            localUserName: '',
            editedUserName: '',
            nameEntryComplete: false,
            userNameValidated: false,
            errorString: '',
            doShowActiveSessionsDialog: false,
        };
    },
    computed: {
        activeSessionsButtonLabel() {
            if (this.activeSessions.length > 0) {
                return 'No thanks';
            }
            return 'Close';
        },
    },
    methods: {
        tryAssignName() {
            if (this.localUserName !== '') {
                this.nameEntryComplete = true;
                this.$emit('assign-user-name', this.localUserName);
            }
            this.playClickAudio();
        },
        tryCreateSession() {
            this.tryAssignEditedName();
            if (this.localUserName !== '') {
                this.$emit('create-session');
                this.playClickAudio();
            } else {
                alert("can't create game!");
            }
        },
        editName() {
            this.editedUserName = this.localUserName;
            this.isEditingName = true;
            this.$nextTick(() => this.$refs.nameEntry.focus());
            this.playClickAudio();
        },
        tryAssignEditedName() {
            if (this.editedUserName !== '') {
                this.localUserName = this.editedUserName;
                this.$emit('assign-user-name', this.localUserName);
                this.isEditingName = false;
            }
            this.playClickAudio();
        },
        showActiveSessionsDialog() {
            this.tryAssignEditedName();
            this.doShowActiveSessionsDialog = true;
            this.playClickAudio();
        },
        hideActiveSessionsDialog() {
            this.doShowActiveSessionsDialog = false;
            this.playClickAudio();
        },
        tryJoinSession(sessionId) {
            this.hideActiveSessionsDialog();
            this.$emit('try-join-session', sessionId);
        },
        tryAdvanceUserNameEntry() {
            if (this.userName !== '') {
                this.localUserName = this.userName;
                this.nameEntryComplete = true;
            }
        },
        playClickAudio() {
            this.$emit('play-audio', AudioEffects.CLICK, -1, 1);
        },
    },
    watch: {
        sessionError: {
            immediate: false,
            handler: function () {
                if (this.sessionError !== '') {
                    if (this.sessionError === 'session-full') {
                        this.errorString = 'Game is full :(';
                    }
                    if (this.sessionError === 'session-does-not-exist') {
                        this.errorString = 'Game does not exist :(';
                    }
                    if (this.sessionError === 'already-joined') {
                        this.errorString = 'Already joined the game :(';
                    }
                    if (this.sessionError === 'name-taken') {
                        this.errorString = 'Username taken :(';
                    }
                }
            },
            userName: {
                immediate: true,
                handler: function () {
                    this.tryAdvanceUserNameEntry();
                },
            },
        },
        sessionId: {
            immediate: false,
            handler: function () {
                this.errorString = '';
            },
        },
    },
    mounted() {
        this.tryAdvanceUserNameEntry();
    },
};
</script>

<style lang="scss" scoped>
.login {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;
}
.__title-container {
    width: 23%;
    min-width: 300px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10vh 0 5vh 0;
}
.__name-row {
    display: flex;
    align-items: baseline;
    gap: 10px;
    height: 50px;
    .__name-read {
        display: flex;
        align-items: baseline;
        gap: 10px;
    }
    .__name-write {
        display: flex;
        align-items: baseline;
        gap: 10px;
        .__input {
            width: 160px;
        }
    }
}
.__form-row {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 35px;
}
.__error-row {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.__form-centered {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.--form-width {
    width: 200px;
}
.--stacked {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.__stack-label {
    position: absolute;
    margin-top: -26px;
}
.--upper-lower {
    text-transform: none;
}
</style>