<template>
    <div class="chat">
        <div class="__header">
            <h2>Messages</h2>
        </div>
        <div class="__scrollable">
            <div class="__message" v-for="(message, index) in messagesReversed" v-bind:key="index">
                <p class="--small">
                    <b
                        v-bind:class="{
                            '--p0': message.authorIndex === -1,
                            '--p1': message.authorIndex === 0,
                            '--p2': message.authorIndex === 1,
                            '--p3': message.authorIndex === 2,
                            '--p4': message.authorIndex === 3,
                        }"
                        >{{ message.authorName }}</b
                    >
                </p>
                <p class="--white">> {{ message.message }}</p>
            </div>
        </div>
        <form class="__controls" v-on:submit.prevent="validateBeforeSend">
            <input class="--border-box --no-right-border --left-align --grow --p10" type="text" name="chat" v-model="currentMessage" />
            <button class="--secondary-button --p10" type="submit">Send</button>
        </form>
    </div>
</template>

<script>
export default {
    name: 'Chat',
    props: {
        messages: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            currentMessage: '',
        };
    },
    computed: {
        messagesReversed() {
            const reversed = [];
            for (let i = this.messages.length - 1; i > -1; i--) {
                reversed.push(this.messages[i]);
            }
            return reversed;
        },
    },
    methods: {
        validateBeforeSend() {
            if (this.currentMessage !== '') {
                this.$emit('send-message', this.currentMessage);
                this.currentMessage = '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.chat {
    position: relative;
    height: calc(100% - 24px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: $color-ui-container;
    border: solid 2px $color-lines;
}

.__header {
    border-bottom: solid 2px $color-lines;
    padding-bottom: 10px;
    h2 {
        margin-left: 20px;
        &:before {
            content: '';
            position: absolute;
            margin-left: -20px;
            margin-top: 12px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #d93e21;
            animation: transmission-blink 1s step-start infinite;
        }
    }
}

@keyframes transmission-blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.__scrollable {
    padding: 10px 0;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-grow: 1;
    flex-direction: column-reverse;
}

.__message {
    margin: 5px 0;
}

.__controls {
    display: flex;
}

.--no-right-border {
    border-right: none;
}
</style>