<template>
    <div class="lobby">
        <top-bar
            v-on:leave="leave"
            v-on:show-story="showStory"
            v-on:copy-id="playClickAudio"
            v-on:volume-changed="volumeChanged"
            v-bind:session-id="sessionId"
            v-bind:volume="volume"
        />
        <div class="__content">
            <div class="__header-row">
                <h1>Space Lobby</h1>
                <div class="__controls">
                    <button class="--secondary-button --lg --p40 --w70" v-on:click="showStory">Briefing</button>
                    <button class="--lg --p40 --w70" v-on:click="startGame">Play →</button>
                </div>
            </div>
            <div class="__divider" />
            <div class="__columns">
                <div class="__column">
                    <h2>Sector</h2>
                    <select name="boardName" v-model="localSelectedBoardIndex">
                        <option v-for="(boardName, index) in boardNames" v-bind:value="index" v-bind:key="index">{{ boardName }}</option>
                    </select>
                    <player-list v-bind:players="users" v-bind:player-id="userId" />
                </div>
                <div class="__column">
                    <chat v-bind:messages="messages" v-on:send-message="sendMessage" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AudioEffects } from '../classes/audioManager.js';

import Chat from '../components/Chat.vue';
import PlayerList from '../components/PlayerList.vue';
import TopBar from '../components/TopBar.vue';

export default {
    name: 'Lobby',
    components: {
        chat: Chat,
        playerList: PlayerList,
        topBar: TopBar,
    },
    props: {
        userId: {
            type: String,
            default: '',
        },
        sessionId: {
            type: String,
            default: '',
        },
        users: {
            type: Array,
            default: () => {
                return [];
            },
        },
        selectedBoardIndex: {
            type: Number,
            default: 0,
        },
        boardNames: {
            type: Array,
            default: () => {
                return [];
            },
        },
        messages: {
            type: Array,
            default: () => {
                return [];
            },
        },
        volume: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            currentMessage: '',
            localSelectedBoardIndex: 0,
        };
    },
    methods: {
        leave() {
            this.$emit('leave-session');
            this.playClickAudio();
        },
        sendMessage(msg) {
            this.$emit('send-message', msg);
            this.playClickAudio();
        },
        startGame() {
            this.$emit('start-game');
            this.playClickAudio();
        },
        playClickAudio() {
            this.$emit('play-audio', AudioEffects.CLICK, -1, 1);
        },
        volumeChanged(val) {
            this.$emit('volume-changed', val);
        },
        showStory() {
            this.$emit('show-story');
        },
    },
    computed: {},
    watch: {
        selectedBoardIndex: {
            immediate: true,
            handler: function () {
                this.localSelectedBoardIndex = this.selectedBoardIndex;
            },
        },
        localSelectedBoardIndex: {
            handler() {
                this.$emit('select-board', this.localSelectedBoardIndex);
                this.playClickAudio();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.lobby {
    position: relative;
    height: 100%;
}

.__content {
    position: relative;
    padding: 40px 10px 0 10px;
    display: flex;
    flex-direction: column;
}

.__header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .__controls {
        display: flex;
        gap: 10px;
    }
}

.__columns {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.__column {
    position: relative;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 450px;
}

.__footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.__row {
    display: flex;
    gap: 5px;
}
</style>