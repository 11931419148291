<template>
    <div class="session-list">
        <div class="__labels" v-bind:class="{ '--hidden': sessions.length === 0 }">
            <label class="--table-name-width --secondary-label"> Captain </label>
            <label class="--secondary-label"> Players </label>
        </div>
        <div class="__content">
            <session-entry
                v-for="session in sessions"
                v-bind:key="session.id"
                v-bind:session="session"
                v-on:try-join-session="tryJoinSession"
            />
            <div class="__no-players-warning" v-if="sessions.length === 0">
                <p>No one's playing, Gus :(</p>
            </div>
        </div>
    </div>
</template>

<script>
import SessionEntry from './SessionEntry.vue';

export default {
    name: 'SessionList',
    components: {
        sessionEntry: SessionEntry,
    },
    props: {
        sessions: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {};
    },
    methods: {
        tryJoinSession(id) {
            this.$emit('try-join-session', id);
        },
    },
};
</script>

<style lang="scss" scoped>
.session-list {
    width: 100%;
    margin-top: 10px;
    .__labels {
        padding: 5px;
        display: flex;
        border: solid 2px transparent;
        width: 250px;
    }
    .__content {
        position: relative;
        height: 180px;
        overflow-y: scroll;
        border: solid 2px $color-lines;
        display: flex;
        flex-direction: column;
        &:after {
            content: '';
            margin-bottom: 10px;
        }
        .__no-players-warning {
            text-align: center;
            margin-top: 20%;
        }
    }
    .--hidden {
        opacity: 0;
    }
}
</style>