<template>
    <div class="top-bar">
        <div class="__left">
            <button class="--secondary-button --p10" v-on:click="$emit('leave')">{{ exitString }}</button>
            <!-- TODO: Removing this functionality for now, possibly remove down the line. -->
            <!-- <button class="--p10 --secondary-button" v-on:click="copySessionId">Copy Game Id</button> -->
            <p v-if="showCopiedText">Copied!</p>
        </div>
        <div class="__center">
            <icon-button v-bind:large="true" v-on:click="$emit('show-story')">
                <template v-slot:icon>
                    <img src="../assets/images/space-command-flag.svg" />
                </template>
            </icon-button>
        </div>
        <div class="__right">
            <label>VOLUME</label>
            <input type="range" class="slider" min="0" max="10" v-model="localVolume" />
        </div>
    </div>
</template>

<script>
import IconButton from './IconButton.vue';

export default {
    name: 'TopBar',
    components: {
        iconButton: IconButton,
    },
    props: {
        sessionId: {
            type: String,
            default: '!',
        },
        exitString: {
            type: String,
            default: 'Leave',
        },
        volume: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            showCopiedText: false,
            localVolume: 5,
        };
    },
    computed: {},
    methods: {
        async copySessionId() {
            await navigator.clipboard.writeText(this.sessionId);
            this.showCopiedText = true;
            setTimeout(() => {
                this.showCopiedText = false;
            }, 3000);
            this.$emit('copy-id');
        },
    },
    watch: {
        localVolume: {
            immediate: false,
            handler: function () {
                this.$emit('volume-changed', this.localVolume / 10);
            },
        },
        volume: {
            immediate: true,
            handler: function (val) {
                this.localVolume = val * 10;
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.top-bar {
    position: relative;
    height: 34px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}
.__center {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.__left {
    display: flex;
    align-items: baseline;
    gap: 5px;
    z-index: 10;
    padding: 0 10px;
    p {
        margin-left: 10px;
    }
}
.__right {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    > label {
        margin-top: 2px;
    }
}
</style>